import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Layout from './components/Layout';
import HomePage from './pages/Home';
import PageNotFound from './pages/PageNotFound';
import TagManager from 'react-gtm-module'

function App() {
  const tagManagerArgs = {
    gtmId: 'GTM-WB5VJG8V'
  }
 
  TagManager.initialize(tagManagerArgs)
  return (
    <Layout>
      <Routes>
        <Route path="/" element={ <HomePage />} />
        <Route path="*" element={ <PageNotFound />} />
      </Routes>
    </Layout>
  );
}

export default App;
