import React, { } from "react"

const Boxes = ( ) => {
    let boxes = [
        {
            title: "Co zyskujesz:",
            desc: `<ul>
                <li>udział w bezpłatnym kursie, obejmującym 77 godzin zajęć teoretycznych zaplanowanych na 10 dni (od poniedziałku do piątku, przez 2 tygodnie, w godzinach 8.00-15.00) oraz 60 godzin zajęć praktycznych według grafików ustalanych indywidualnie,</li>
                <li>pozwolenie na kierowanie tramwajem, po pozytywnie zdanym egzaminie,</li>
                <li>stabilne zatrudnienie w spółce Tramwaje Szczecińskie (w tym pakiet socjalny oraz “bilet” na komunikację miejską),</li>
                <li>wynagrodzenie początkowe na poziomie 6200 zł.</li>
            </ul>`
        },
        {
            title: "Nasze wymagania:",
            desc: `<ul>
                <li>ukończone 21 lat,</li>
                <li>niekaralność (w stosunku do kandydata nie może być orzeczony prawomocnym wyrokiem sądu zakaz prowadzenia pojazdów mechanicznych, ani wydana decyzja o zatrzymaniu prawa jazdy),</li>
                <li>szukamy właśnie Ciebie, jeśli odznaczasz się wysoką kulturą osobistą, jesteś opanowany i potrafisz sobie radzić w sytuacjach stresujących, czujesz odpowiedzialność za powierzone zadania i realizujesz je z zaangażowaniem, cieszysz się dobrą sprawnością psychofizyczną; znajomość topografii Szczecina, będzie dodatkowym atutem.</li>
            </ul>`
        },
        {
            title: "Oferujemy:",
            desc: `<ul>
                <li>bezpłatny kurs motorniczego tramwaju, dający uprawnienia do prowadzenia tramwaju,</li>
                <li>stabilne i pewne zatrudnienie (średni staż pracy w spółce to 17 lat),</li>
                <li>dofinansowanie podnoszenia kwalifikacji,</li>
                <li>możliwość rozwoju zawodowego i awansu,</li>
                <li>pracę w firmie z tradycjami w zakresie lokalnego transportu zbiorowego,</li>
                <li>świadczenia z Zakładowego Funduszu Świadczeń Socjalnych,</li>
                <li>dostęp do ubezpieczenia na życie.</li>
            </ul>`
        }
    ]
    return (
        <section class="boxes">
            <div className="container">
                <div className="boxes-wrapper">
                    {boxes && boxes.map((box, index) => (
                        <div className="box">
                            <div className="icon">
                                <p>{++index}</p>
                            </div>
                            <div className="title">
                                <h3>{box.title}</h3>
                            </div>
                            <div className="desc" dangerouslySetInnerHTML={ { __html: box.desc } } />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )

}



export default Boxes;