import React, { useState, useEffect } from "react";
import Input from '../input/Input';
import Textarea from '../input/Textarea';
import DatePicker from '../input/DatePicker';
import Checkbox from '../input/Checkbox';
import Select from '../input/Select';
import Button from '../button';
import Label from './../label';
import { selectData } from '../../services/selectData';
import { getApiLink } from '../../services/getApiLink';
import Categories from './categories.json';
import Upload from './../upload';
import RemoveIcon from './../../svg-components/PlusIcon';
import axios from 'axios';
import Popup from './../popup';

const Form = (props) => {
    let [ load, setLoad ] = useState(false);
    let [ disabledButton, setDisabledButton ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ buttonText, setButtonText ] = useState("Wyślij zgłoszenie");
    let [ name, setName ] = useState("");
    let [ email, setEmail ] = useState("");
    let [ phone, setPhone ] = useState("");
    let [ address, setAddress ] = useState("");
    let [ dateBirth, setDateBirth ] = useState(false);
    let [ drivingLicence, setDrivingLicence ] = useState(-1);
    let [ experience, setExperience ] = useState([])
    let [ education, setEducation ] = useState([]);
    let [ training, setTraining ] = useState([]);
    let [ cv, setCV ] = useState(false);
    let [ regulation, setRegulation ] = useState(false);
    let [ newsletter, setNewsletter ] = useState(false);
    let [ reset, setReset ] = useState(false);
    let [ popup, setPopup ] = useState(false);

    useEffect(() => {
        if(!load) {
            // generateForm();
        }
    }, [load])

    const generateForm = () => {
        setName("Jan Bąk");
        setEmail("jan.bak@norddigital.com");
        setPhone("123123123");
        setAddress("Ulica Janka Bąka Brenna");
        setDateBirth("15/09/1111");
        setDrivingLicence(0);
        setExperience([
            {
                position: "Burmistrz",
                employer: "Bóg",
                dateStart: "12/01/1999",
                dateEnd: "13/12/2222"
            },
            {
                position: "Marynarz",
                employer: "Ryba",
                dateStart: "11/11/1111",
                dateEnd: "22/12/2222"
            }
        ])
        setEducation([
            {
                education: "Niższa",
                science: "WSRH",
                dateEnd: "13/12/2232"
            },
            {
                education: "WYŻSZE",
                science: "zerówka",
                dateEnd: "22/12/2262"
            }
        ])
        setTraining([
            {
                otherCourse: "Zjadanie węży"
            },
            {
                otherCourse: "kradzież torów"
            }
        ])
    }

    const options = selectData(Categories);

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        let scroll = await scrollPage();
        if ( !scroll ) {
            await setForceValidate(false);
            await save();
        }
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    const scrollPage = () => {
        let result;
        let elements = document.querySelectorAll('form .error');
        if( elements.length > 0) {
            let rect = elements[0].getBoundingClientRect();
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: rect.top + scrollTop - 10
              });
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const save = async() => {
        if ( !disabledButton ) {
            setDisabledButton(true);
            setButtonText('Przesyłam formularz...')
            const formData = new FormData(); 
            formData.append("name", name); 
            formData.append("email", email);
            formData.append('phone', phone);
            formData.append('address', address);
            formData.append('dateBirth', dateBirth);
            formData.append('drivingLicence', drivingLicence != -1 ? options.items[drivingLicence].title : false);
            formData.append('experience', JSON.stringify(experience));
            formData.append('education', JSON.stringify(education));
            formData.append('training', JSON.stringify(training));
            formData.append('cv', cv);
            formData.append('regulation', regulation);
            formData.append('newsletter', newsletter);
            formData.append('t', 'sdf7832jkS12');
            let ax = await axios({
                method: 'post',

                url: `${ getApiLink() }/sendEmail.php`,
                config: { headers: {'Content-Type': 'multipart/form-data' }},
                data: formData,
            });
            let res = JSON.parse(ax.request.response);

            if(res.status) {
                await setButtonText('Wysłano');
                await setPopup(true);
                await setName("");
                await setPhone("");
                await setEmail("");
                await setAddress("");
                await setDateBirth(false);
                await setDrivingLicence(-1);
                await setExperience([]);
                await setEducation([]);
                await setTraining([]);
                await setCV(false);
                await setRegulation(false);
                await setNewsletter(false);
                await setReset(true);
                await wait(400);
                await setReset(false);
                // setDisabledButton(false);
            }
        }
    }

    const changeValueIn = (type, index, label, val) => {
        if(type == "experience") {
            let copyData = experience;
            copyData = copyData.map((item, i) => i == index ? ({...item, [label]: val}) : item);
            setExperience(copyData)
        }else if(type == "education") {
            let copyData = education;
            copyData = copyData.map((item, i) => i == index ? ({...item, [label]: val}) : item);
            setEducation(copyData)
        }else if(type == "training") {
            let copyData = training;
            copyData = copyData.map((item, i) => i == index ? ({...item, [label]: val}) : item);
            setTraining(copyData)
        }
    }

    const addNewField = (type) => {
        if(type == "experience") {
            let newExperience = [];
            let newItem = {
                position: "",
                employer: "",
                dateStart: "",
                dateEnd: ""
            }
            if(experience && experience.length > 0) {
                newExperience = [...experience, newItem];
            }else{
                newExperience = [newItem];
            }
            setExperience(newExperience);
        }else if(type == "education") {
            let newEducation = [];
            let newItem = {
                education: "",
                science: "",
                dateEnd: ""
            }
            if(education && education.length > 0) {
                newEducation = [...education, newItem];
            }else{
                newEducation = [newItem];
            }
            setEducation(newEducation);
        }else if(type == "training") {
            let newTraining = [];
            let newItem = {
                otherCourse: "",
            }
            if(training && training.length > 0) {
                newTraining = [...training, newItem];
            }else{
                newTraining = [newItem];
            }
            setTraining(newTraining);
        }
    }

    const removeField = (type, index) => {
        if(type == "experience") {
            let newExperience = experience;
            newExperience = newExperience.filter((item, i) => index == i ? false : item);
            setExperience(newExperience);
        }else if(type == "education") {
            let newEducation = education;
            newEducation = newEducation.filter((item, i) => index == i ? false : item);
            setEducation(newEducation);
        }else if(type == "training") {
            let newTraining = training;
            newTraining = newTraining.filter((item, i) => index == i ? false : item);
            setTraining(newTraining);
        }
    }

    return (
        <section className="form" id="wyslij-cv">
            <form>
                {popup &&
                    <Popup text={"<h2>Twoje zgłoszenie zostało pomyślnie wysłane</h2>"} close={() => setPopup(false)} />
                }
                {/* DANE */}
                <div className="group">
                    <Label text="Twoje dane" />
                    <div className="fields">
                        <div className="row-item">
                            <Input
                                placeholder={"imię i nazwisko"}
                                required={ true }
                                type="text" 
                                name="name"
                                allowChars={/.*/}
                                minChar={ 1 } 
                                forceValidate={ forceValidate }
                                reset={reset}
                                value={name}
                                setFunction={ ( val ) => setName(val)} />
                        </div>
                        <div className="row-item">
                            <Input
                                placeholder={"Adres e-mail"}
                                required={ true }
                                type="text" 
                                name="email"
                                regExp={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/}
                                allowChars={/.*/}
                                minChar={ 1 } 
                                forceValidate={ forceValidate }
                                reset={reset}
                                value={email}
                                setFunction={ ( val ) => setEmail(val)} />
                        </div>
                        <div className="row-item">
                            <Input
                                placeholder={"nr telefonu"}
                                required={ true }
                                type="text" 
                                name="phone"
                                regExp={/^[+0-9]{9,13}$/}
                                allowChars={/^[+0-9]{0,13}$/}
                                minChar={ 1 } 
                                forceValidate={ forceValidate }
                                reset={reset}
                                value={phone}
                                setFunction={ ( val ) => setPhone(val)} />
                        </div>
                        <div className="row-item">
                            <Input
                                placeholder={"Adres"}
                                required={ true }
                                type="text" 
                                name="address"
                                allowChars={/.*/}
                                minChar={ 1 } 
                                forceValidate={ forceValidate }
                                reset={reset}
                                value={address}
                                setFunction={ ( val ) => setAddress(val)} />
                        </div>
                        <div className="row-item">
                            <DatePicker required forceValidate={forceValidate} value={dateBirth} setFunction={(val) => setDateBirth(val)} placeholder="Data urodzenia (dd/mm/rrrr)" minAge="21"  reset={reset} />
                        </div>
                    </div>
                </div>
                {/* DOŚWIADCZENIE */}
                <div className="group">
                    <Label text="Doświadczenie zawodowe" info="Możesz dodać swoje doświadczenie zawodowe, ale nie jest to wymagane" arrow={true} actionClass="fields-1" clickToggle={() => experience.length == 0 ? addNewField("experience") : false} />
                    <div className="fields fields-1 hidden" id="fields-1">
                        {experience && experience.map((item, index) => (
                            <>
                            <div className="row-item">
                                <Input
                                    placeholder={"Stanowisko"}
                                    required={ false }
                                    type="text" 
                                    name="position"
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    forceValidate={ forceValidate }
                                    reset={reset}
                                    value={item.position}
                                    setFunction={ ( val ) => changeValueIn("experience", index, "position", val)} />
                            </div>
                            <div className="row-item">
                                <Input
                                    placeholder={"Pracodawca"}
                                    required={ false }
                                    type="text" 
                                    name="employer"
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    forceValidate={ forceValidate }
                                    reset={reset}
                                    value={item.employer}
                                    setFunction={ ( val ) => changeValueIn("experience", index, "employer", val)} />
                            </div>
                            <div className="row-item dates">
                                <DatePicker value={item.dateStart} setFunction={(val) => changeValueIn("experience", index, "dateStart", val)} placeholder="dd/mm/rrrr" title="Data rozpoczęcia"  reset={reset} />
                                <DatePicker value={item.dateEnd} setFunction={(val) => changeValueIn("experience", index, "dateEnd", val)} placeholder="dd/mm/rrrr" title="Data zakończenia"  reset={reset} />
                                <div className="remove-item" onClick={() => removeField("experience", index)}>
                                    <RemoveIcon />
                                </div>
                            </div>
                            <div className="mobile-border" />
                            </>
                        ))}
                    </div>
                    <div className="row-item fields-1 hidden">
                        <Button setFunction={() => addNewField("experience")} title={"Dodaj kolejne"} plus />
                    </div>
                </div>
                {/* WYKSZTAŁCENIE */}
                <div className="group">
                    <Label text="Wykształcenie" info="Możesz dodać swoje wykształcenie, ale nie jest to wymagane" arrow={true} actionClass="fields-2" clickToggle={() => education.length == 0 ? addNewField("education") : false} />
                    <div className="fields fields-2 hidden" id="fields-2">
                        {education && education.map((item, index) => (
                            <>
                            <div className="row-item">
                                <Input
                                    placeholder={"Wykształcenie"}
                                    required={ false }
                                    type="text" 
                                    name="education"
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    forceValidate={ forceValidate }
                                    reset={reset}
                                    value={item.education}
                                    setFunction={ ( val ) => changeValueIn("education", index, "education", val)} />
                            </div>
                            <div className="row-item">
                                <Input
                                    placeholder={"Kierunek/klasa"}
                                    required={ false }
                                    type="text" 
                                    name="science"
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    forceValidate={ forceValidate }
                                    reset={reset}
                                    value={item.science}
                                    setFunction={ ( val ) => changeValueIn("education", index, "science", val)} />
                            </div>
                            <div className="row-item date">
                                <DatePicker value={item.dateEnd} setFunction={(val) => changeValueIn("education", index, "dateEnd", val)} placeholder="dd/mm/rrrr" title="Data zakończenia" reset={reset} />
                                <div className="remove-item" onClick={() => removeField("education", index)}>
                                    <RemoveIcon />
                                </div>
                            </div>
                            <div className="mobile-border" />
                            </>
                        ))}
                    </div>
                    <div className="row-item fields-2 hidden">
                        <Button setFunction={() => addNewField("education")} title={"Dodaj kolejne"} plus />
                    </div>
                </div>
                {/* KURSY / SZKOLENIA */}
                <div className="group">
                    <Label text="Kursy / szkolenia" info="Możesz dodać np. informacje o posiadanym prawie jazdy, ale nie jest to wymagane" arrow={true} actionClass="fields-3" clickToggle={() => training.length == 0 ? addNewField("training") : false} />
                    <div className="fields fields-3 hidden">
                        <div className="row-item">
                            <Select data={ options } 
                                selectIndex={ drivingLicence }
                                reset={reset}
                                setFunction={ ( val ) => setDrivingLicence(val)} />
                        </div>
                        <div className="row-item hidden-item" />
                        <div className="row-item hidden-item" />
                    </div>
                    <div className="fields fields-3 hidden" id="fields-3">
                        {training && training.map((item, index) => (
                            <>
                            <div className="row-item">
                                <Input
                                    placeholder={"Inne kursy"}
                                    required={ false }
                                    type="text" 
                                    name="other-course"
                                    allowChars={/.*/}
                                    minChar={ 1 } 
                                    forceValidate={ forceValidate }
                                    reset={reset}
                                    value={item.otherCourse}
                                    setFunction={ ( val ) => changeValueIn("training", index, "otherCourse", val)} />
                                    <div className="remove-item" onClick={() => removeField("training", index)}>
                                        <RemoveIcon />
                                    </div>
                            </div>
                            <div className="row-item hidden-item" />
                            <div className="row-item hidden-item" />
                            <div className="mobile-border" />
                            </>
                        ))}
                    </div>
                    <div className="row-item fields-3 hidden">
                        <Button setFunction={() => addNewField("training")} title={"Dodaj kolejne"} plus />
                    </div>
                </div>
                {/* ZAŁADUJ CV */}
                <div className="group">
                    <Label text="Załaduj CV" arrow={true} actionClass="fields-4" info="Możesz dodać swoje CV, ale nie jest to wymagane." />
                    <div className="finish fields-4 hidden" id="fields-4">
                        <Upload  
                            setFunction={ val => setCV( val ) }
                            value={ cv }
                            regExp={ /.*/ }
                        />
                    </div>
                    <div className="row-item">
                        <Checkbox text={"Wyrażam zgodę na przetwarzanie moich danych zgodnie z <a href='https://bip.ts.szczecin.pl/artykul/klauzula-informacyjna'>klauzulą informacyjną</a>"} 
                            required={ true }
                            forceValidate={ forceValidate }
                            setFunction={ ( val ) => setRegulation(val)}
                            reset={ reset } />
                    </div>
                    <div className="row-item">
                        <Checkbox text={"Chcę otrzymywać Newsletter"} 
                            required={ false }
                            forceValidate={ forceValidate }
                            setFunction={ ( val ) => setNewsletter(val)}
                            reset={ reset } />
                    </div>
                    <div className="row-item submit">
                        <Button setFunction={() => validate()} title={buttonText} arrow />
                    </div>
                </div>
            </form>
        </section>
    );
};

export default Form;