import React, { } from "react";
import Logo from './../../svg-components/Logo';

const Header = ( ) => {

    const onClick = (id) => {
        const anchorComment = document.getElementById(id);
        if(anchorComment) {
            let rect = anchorComment.getBoundingClientRect();
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: rect.top + scrollTop - 10
            });
        }
    }
    return (
        <header>
            <div className="logo">
                <Logo />
            </div>
            <nav>
                <ul>
                    <li>
                        <a href="#" onClick={() => onClick("wyslij-cv")}>Wyślij zgłoszenie</a>
                    </li>
                    <li>
                        <a href="#" onClick={() => onClick("o-kursie")}>O kursie</a>
                    </li>
                </ul>
            </nav>
        </header>
    )

}



export default Header;