import React, { } from "react"
import bannerImg from './../../images/banner.png';

const Banner = ({ video }) => {

    return (
        <section class="banner">
            <div className="container">
                <div className="bg-wrapper">
                    {/* <img src={bannerImg} alt="zostań motorniczym" /> */}
                    {video &&
                        <video width="1920" height="550" autoPlay loop muted playsInline>
                            <source src={video} type="video/mp4" />
                        </video>
                    }
                </div>
                <div className="text-wrapper">
                    <h2>ZOSTAŃ MOTORNICZYM</h2>
                </div>
            </div>
        </section>
    )

}



export default Banner;